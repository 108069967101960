import './App.css';

import {
  HashRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Header from './header';
import Footer from './footer';
import Landing from './landing';
import Pricing from './pricing';
import Materials from './materials';

function App() {
  return (
    <div className="bg-white">
      <Router>
        <Header />

        <Routes>
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/materials" element={<Materials />} />
          <Route path="/" element={<Landing />} />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
