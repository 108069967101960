const contactDetails = [
  { name: 'Tact - 1 item 3', linkName: 'download', link: 'https://drive.google.com/file/d/1rszAlY8P-HiKYr7oKCEAxFA2X2HD90V_/view?usp=sharing' },
  { name: 'Tact - 1 item 5', linkName: 'download', link: 'https://drive.google.com/file/d/1ugCEC89IUFlkx8RLlGIzyd5UrTwh-Iam/view?usp=sharing' },
  { name: 'Tact - 1 item 6', linkName: 'download', link: 'https://drive.google.com/file/d/1PPITMxuCL9y3CAXqfl7MtZxo2VMLjwGH/view?usp=sharing' },
]
const locations = [
  { city: 'Los Angeles', address: ['4556 Brendan Ferry', 'Los Angeles, CA 90210'] },
  { city: 'New York', address: ['886 Walter Streets', 'New York, NY 12345'] },
  { city: 'Toronto', address: ['7363 Cynthia Pass', 'Toronto, ON N3Y 4H8'] },
  { city: 'Chicago', address: ['726 Mavis Island', 'Chicago, IL 60601'] },
]
const faqs = [
  {
    id: 1,
    question: 'How do you make holy water?',
    answer:
      'You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.',
  },
  {
    id: 2,
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    id: 3,
    question: 'What do you call someone with no body and no nose?',
    answer: 'Nobody knows. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.',
  },
  {
    id: 4,
    question: 'Why do you never see elephants hiding in trees?',
    answer:
      "Because they're so good at it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
]

export default function Example() {
  return (
    <div className="bg-white">

      <header className="relative pb-24 bg-sky-800 sm:pb-32">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
            alt=""
          />
          <div
            className="absolute inset-0 bg-gradient-to-l from-sky-800 to-cyan-700 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="relative pt-24 max-w-md mx-auto px-4 sm:max-w-3xl sm:pt-32 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">Materials Library</h1>
          <p className="mt-6 text-xl text-cyan-100 max-w-3xl">
            ABAsession.org provides tools to support practitioners of Applied Behavior Analysis as they maintain and grow their practice.
            Our tools focus on service delivery in person and via telehealth.
          </p>
        </div>
      </header>

      <main>
        {/* Side-by-side grid */}
        <div className="bg-white">
          <div className="max-w-md mx-auto py-24 px-4 sm:max-w-3xl sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="divide-y divide-stone-200">
              <section className="lg:grid lg:grid-cols-3 lg:gap-8" aria-labelledby="contact-heading">
                <div>
                  <h2 id="contact-heading" className="text-2xl font-extrabold text-stone-900 sm:text-3xl">
                    VB-MAPP: Tacting
                  </h2>
                  <p className="mt-6 text-base text-stone-500">
                    This free materials set has been designed exclusively for ABA practitioners to support all categories and levels of the VB-MAPP.
                  </p>
                </div>
                <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                  {contactDetails.map((item) => (
                    <div key={item.name}>
                      <h3 className="text-lg font-medium text-stone-900">{item.name}</h3>
                      <dl className="mt-2 text-base text-stone-500">
                        <div>
                          <dt className="sr-only">Email</dt>
                          <dd>
                            <a className="hover:underline" href={item.link} target="_blank" rel="noreferrer">
                              {item.linkName}
                            </a>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  ))}
                </div>
              </section>

              <section className="mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-8 hidden lg:hidden" aria-labelledby="location-heading">
                <h2 id="location-heading" className="text-2xl font-extrabold text-stone-900 sm:text-3xl">
                  Locations
                </h2>
                <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                  {locations.map((location) => (
                    <div key={location.city}>
                      <h3 className="text-lg font-medium text-stone-900">{location.city}</h3>
                      <div className="mt-2 text-base text-stone-500 space-y-1">
                        {location.address.map((line) => (
                          <p key={line}>{line}</p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </div>
        </div>

        {/* FAQ */}
        <div className="hidden bg-stone-50">
          <div className="max-w-md mx-auto py-24 px-4 sm:max-w-3xl sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <h2 className="text-3xl font-extrabold text-stone-900">Frequently asked questions</h2>
                <p className="mt-4 text-lg text-stone-500">
                  Can’t find the answer you’re looking for? Reach out to our{' '}
                  <a href="#_" className="font-medium text-cyan-700 hover:text-cyan-600">
                    customer support
                  </a>{' '}
                  team.
                </p>
              </div>
              <div className="mt-12 lg:mt-0 lg:col-span-2">
                <dl className="space-y-12">
                  {faqs.map((faq) => (
                    <div key={faq.id}>
                      <dt className="text-lg font-medium text-stone-900">{faq.question}</dt>
                      <dd className="mt-2 text-base text-stone-500">{faq.answer}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>

        {/* Newsletter */}
        <div className="hidden relative">
          <div className="absolute left-0 right-0 h-1/2 bg-stone-50" aria-hidden="true" />
          <div className="relative max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="py-10 px-6 bg-gradient-to-l from-sky-800 to-cyan-700 rounded-3xl sm:py-16 sm:px-12 lg:py-20 lg:px-20 lg:flex lg:items-center">
              <div className="lg:w-0 lg:flex-1">
                <h2 className="text-3xl font-extrabold tracking-tight text-white">Sign up for our newsletter</h2>
                <p className="mt-4 max-w-3xl text-lg text-cyan-100">
                  Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui Lorem cupidatat commodo. Elit sunt
                  amet fugiat.
                </p>
              </div>
              <div className="mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 lg:flex-1">
                <form className="sm:flex">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email-address"
                    type="email"
                    autoComplete="email"
                    required
                    className="w-full border-white px-5 py-3 placeholder-stone-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cyan-700 focus:ring-white rounded-md"
                    placeholder="Enter your email"
                  />
                  <button
                    type="submit"
                    className="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-400 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cyan-700 focus:ring-green-400 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                  >
                    Notify me
                  </button>
                </form>
                <p className="mt-3 text-sm text-cyan-100">
                  We care about the protection of your data. Read our{' '}
                  <a href="#_" className="text-white font-medium underline">
                    Privacy Policy.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
